import React from 'react';
import maintenanceImage from '../assets/images/maintenance.png';

const Maintenance = () => {
  return (
    <div className="maintenance">
      <div className="maintenance_container">
        <div>
          <h1>Maintenance Notice</h1>
          <p>
            Dear Members, Our platform is currently undergoing maintenance and will be unavailable
            until further notice. We will update you as soon as everything is back to normal. We
            apologize for any inconvenience this may cause.
            <br />
            <br />
            Best regards,
            <br />
            Quest Team.
          </p>
        </div>
        <div className="maintenance_imageWrapper">
          <img src={maintenanceImage} alt="maintenance" />
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
